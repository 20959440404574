<template>
  <el-container>
    <el-header class="header1" height="32">
      <el-row type="flex" justify="start">
        <div class="back">
          <Icon iconName="arrow_left_white" />
          <Icon iconName="letter_a_white" />
        </div>
        <BreadCrumbs />
        <UserProfileButton /> </el-row
    ></el-header>
    <el-header class="header2" height="32"
      ><el-row type="flex" justify="start" align="center">
        <TIButton icon="plus_green" @onClick="createNewRestrict" :title="$t('Створити нову умову')" />
        <TIButton icon="edit_yellow" @onClick="editRestrict" :disabled="!selectedRestrictId" :title="$t('Редагувати')" />
        <TIButton icon="delete_red" @onClick="deleteRestrict" :disabled="!selectedRestrictId" :title="$t('Видалити')" />
        <TSeparator />
        <el-dropdown trigger="click" @command="handleClick">
          <span class="el-dropdown-link">
            <TIButton icon="import_blue" :label="$t('Імпорт')" dropdown="true" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="import-restrict-from-xlsx"
              ><img class="svg" :src="require('../assets/icons/import_blue.svg')" />{{ $t("із Excel (.xlsx)") }}</el-dropdown-item
            >
            <el-dropdown-item command="import-resrtict-from-json" divided
              ><img class="svg" :src="require('../assets/icons/import_blue.svg')" />{{ $t("із JSON") }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" @command="handleClick">
          <span class="el-dropdown-link">
            <TIButton icon="export_blue" :label="$t('Експорт')" dropdown="true" :disabled="!selectedRestrictId" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="export-to-json" :disabled="!selectedRestrictId"
              ><img class="svg" :src="require('../assets/icons/export_blue.svg')" />{{ $t("в JSON") }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <TSeparator />
        <TIButton
          icon="copy_green"
          :label="$t('Створити копію')"
          @onClick="copyRestrict"
          :disabled="!selectedRestrictId"
          :title="$t('Створити копію обраної умови')"
        />
        <TSeparator />
        <TIButton
          icon="copy_green"
          :label="$t('Розмножити структури')"
          @onClick="cloneStructures"
          :disabled="!selectedRestrictId"
          :title="$t('Розмножити структури обраної умови на усі умови')"
        />
        <TSeparator />
        <TIButton
          icon="copy_green"
          :label="$t('Розмножити товари')"
          @onClick="cloneSKU"
          :disabled="!selectedRestrictId"
          :title="$t('Розмножити товари обраної умови на усі умови')"
        />
        <TSeparator />
      </el-row>
    </el-header>
    <el-header class="header3" height="48"
      ><el-row type="flex" justify="start">
        <div style="padding-left:19px;">
          <el-input
            :placeholder="$t('Пошук')"
            style="width:308px;"
            prefix-icon="el-icon-search"
            clearable
            v-model="filterByName"
            ref="inputFilterName"
            @keydown.esc.native="resetFilterName"
          />
        </div> </el-row
    ></el-header>
    <el-main
      ><ag-grid-vue
        ref="restrictsTable"
        style="width: 100%; height: 100%;"
        class="ag-theme-alpine"
        :defaultColDef="defaultColDef"
        :columnDefs="columns"
        :headerHeight="32"
        :rowData="restricts"
        :context="context"
        :gridOptions="gridOptions"
        :frameworkComponents="frameworkComponents"
        :enableBrowserTooltips="true"
        rowSelection="multiple"
        @selection-changed="onGridSelectionChanged"
        @rowDoubleClicked="onGridDoubleClick"
        @sortChanged="onGridColumnsChange"
        @columnResized="onGridColumnsChange"
        @columnMoved="onGridColumnsChange"
        @displayedColumnsChanged="onGridColumnsChange"
        :overlayNoRowsTemplate="noRowsToShowTemplate"
        :modules="modules"
        :localeTextFunc="gridLocale"
        :sideBar="sideBar"
        :getContextMenuItems="contextMenuItems"
      >
      </ag-grid-vue
    ></el-main>
    <EditRestrict @commit="restrictCommitChanges" />
    <SelectFile @commit="restrictImportData" bus-event="restricts.forms.select.file" />
    <SelectFileForImportRestrictsFromXLS @commit="restrictImportDataFromXLS" bus-event="restricts.forms.select.file.xls" />
    <SelectRestricts @select-commit="restrictSelectDataCommit" bus-event="forms.restricts.select" />
  </el-container>
</template>

<script>
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import Icon from "@/components/IconPack";
import TIButton from "@/components/ToolbarIconButton";
import TSeparator from "@/components/ToolbarSeparator";
import { bus } from "@/main";
import GridCellWithIcons from "@/components/GridCellWithIcons.js";
import BreadCrumbs from "@/components/BreadCrumbs";
import UserProfileButton from "@/components/UserProfileButton";
import { _ } from "vue-underscore";

function parseError(error) {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("; ");
  } else return error;
}

const Restricts = {
  name: "Restricts",
  components: {
    AgGridVue,
    TIButton,
    TSeparator,
    Icon,
    BreadCrumbs,
    UserProfileButton,
    EditRestrict: () => import("@/components/forms/restricts/EditRestrict"),
    SelectFile: () => import("@/components/forms/SelectFile"),
    SelectFileForImportRestrictsFromXLS: () => import("@/components/forms/restricts/SelectFileForImportRestrictsFromXLS"),
    SelectRestricts: () => import("@/components/forms/restricts/SelectRestricts"),
  },
  beforeMount() {
    var that = this;
    this.frameworkComponents = { GridCellWithIcons: GridCellWithIcons };
    this.context = { componentParent: this };
    this.columns = [
      {
        headerName: $tt("Назва"),
        field: "name",
        flex: 1,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        cellRenderer: "GridCellWithIcons",
        cellIcons: [
          {
            icon: "edit",
            iconClass: "icon-action",
            type: "fas el-icon-fa-",
            color: "green",
            title: $tt("Редагувати"),
            onClick: (params) => {
              this.editRestrict(params.data._id);
            },
            show: function(params) {
              return true;
            },
          },
          {
            icon: "eye",
            iconClass: "icon-action",
            type: "fas el-icon-fa-",
            color: "green",
            title: $tt("Дивитися"),
            onClick: (params) => {},
            show: function(params) {
              return false;
            },
          },
          {
            icon: "trash",
            iconClass: "icon-action",
            type: "fas el-icon-fa-",
            color: "red",
            title: $tt("Видалити"),
            onClick: (params) => {
              this.deleteRestrict(null, params);
            },
          },
          {
            icon: "info-circle",
            type: "fas el-icon-fa-",
            color: "blue",
            iconClass: "icon-action",
            title: $tt("Інформація"),
            onClick: (params) => {},
          },
        ],
      },
      {
        headerName: $tt("Тип умови"),
        field: "type",
        width: "120px",
        valueGetter: function(params) {
          return $tt(that.selectOptions.restricts.type.find((option) => option.value == params.data.type).label);
        },
        cellClass: function(params) {
          switch (params.data.type) {
            case "set":
              return "grid-cell-green-text";
            case "stock":
              return "grid-cell-yellow-text";
            case "sale":
              return "grid-cell-navy-text";
            default:
              return "";
          }
        },
        filter: "agSetColumnFilter",
      },
      {
        headerName: $tt("Дата початку"),
        field: "beginDate",
        valueGetter: function(params) {
          return new Date(params.data.beginDate).toLocaleDateString();
        },
        width: "140px",
        filter: "agDateColumnFilter",
      },
      {
        headerName: $tt("Дата закінчення"),
        field: "endDate",
        valueGetter: function(params) {
          return new Date(params.data.endDate).toLocaleDateString();
        },
        width: "140px",
        filter: "agDateColumnFilter",
      },
      {
        headerName: $tt("Структури"),
        field: "orgs",
        width: "150px",
        valueGetter: function(params) {
          return params.data._orgsCount;
        },
        cellClass: function(params) {
          return params.data._orgsCount > 0 ? "ag-right-aligned-cell" : "grid-cell-red-text ag-right-aligned-cell";
        },
      },
      {
        headerName: $tt("Товари"),
        field: "sku",
        width: "150px",
        valueGetter: function(params) {
          return params.data._skuCount;
        },
        cellClass: function(params) {
          return params.data._skuCount > 0 ? "ag-right-aligned-cell" : "grid-cell-red-text ag-right-aligned-cell";
        },
      },
      {
        headerName: $tt("Правила план/бонус"),
        field: "planBonus",
        width: "150px",
        valueGetter: function(params) {
          return params.data._rulesCount;
        },
        cellClass: function(params) {
          return params.data._rulesCount > 0 ? "ag-right-aligned-cell" : "grid-cell-red-text ag-right-aligned-cell";
        },
      },
      {
        headerName: $tt("Примітка"),
        field: "notes",
        flex: 1,
      },
    ].map((column) => _.extend(column, { headerTooltip: column.headerName }));
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.restoreGridSettings(this, this.gridOptions, "restricts", this.columns, $tt("Умови"));
    window.addEventListener("keydown", this.onKeyDownHandler);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.onKeyDownHandler);
  },
  data() {
    return {
      modules: AllModules,
      selectedRestrictId: null,
      gridApi: null,
      frameworkComponents: null,
      context: null,
      noRowsToShowTemplate: `<div><img src="${require("../assets/icons/no_data.svg")}"></div>`,
      gridOptions: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data._id;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      sideBar: {
        toolPanels: ["columns", "filters"],
        position: "left",
        hiddenByDefault: false,
      },
      filterByName: "",
      filterByDateFrom: null, // new Date().setHours(0, 0, 0, 0),
      filterByDateTo: null, //new Date().setHours(23, 0, 0, 0),

      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        headerTooltip: function(params) {
          return "123";
        },
        suppressMenu: false,
        filter: "agSetColumnFilter",
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      columns: null,
    };
  },
  computed: {
    restricts() {
      return this.$store.getters["restricts/restrictsStoreFiltered"]({
        byName: this.filterByName,
        byDateFrom: this.filterByDateFrom,
        byDateTo: this.filterByDateTo,
      });
    },
    filterByDateValid() {
      if (this.filterByDateFrom && this.filterByDateTo) {
        if (Date.parse(this.filterByDateFrom) > Date.parse(this.filterByDateTo)) {
          return false;
        } else return true;
      } else return true;
    },
    contract() {
      return this.$store.getters["contracts/contract"]();
    },
    selectOptions() {
      return this.$store.getters["main/selectionOptions"];
    },
  },
  methods: {
    resetFilterName(event) {
      this.$refs.inputFilterName.clear();
    },

    getRestrictById(id) {
      if (id) {
        return this.$store.getters["restricts/getRestrictById"](id);
      } else return null;
    },
    getSelectedRestrict() {
      if (this.selectedRestrictId) {
        return this.$store.getters["restricts/getRestrictById"](this.selectedRestrictId);
      } else return null;
    },
    wait(timeout) {
      return new Promise((resolve) => {
        setTimeout(resolve, timeout);
      });
    },
    onButtonClick(target) {
      this.$message.success("click");
    },
    onGridDoubleClick(data) {
      this.editRestrictDetails();
    },
    onGridSelectionChanged() {
      var rows = this.gridApi.getSelectedRows();
      if (rows && rows.length > 0) {
        this.selectedRestrictId = rows[0]._id;
      } else {
        this.selectedRestrictId = null;
      }
    },
    createNewRestrict() {
      var contract = this.$store.getters["contracts/contract"]();
      bus.$emit("forms.restricts.edit.show", {
        title: $tt("Створення умови"),
        operation: "create",
        initModel: {
          name: "_test_restrict_" + new Date().toLocaleDateString(),
          orgs: [],
          sku: [],
          type: "purchase",
          planMeasure: contract.planMeasure ? contract.planMeasure : "pcs",
          bonusMeasure: "pctOfSIPPrice",
          beginDate: contract.beginDate, // new Date(new Date().setHours(0, 0, 0, 0)),
          endDate: contract.endDate, // new Date(new Date().setHours(0, 0, 0, 0)),
        },
      });
    },
    editRestrict(id) {
      var restrict = _.isObject(id) ? this.getSelectedRestrict() : this.getRestrictById(id);
      if (restrict) {
        bus.$emit("forms.restricts.edit.show", { title: $tt("Редагування умови"), operation: "update", initModel: restrict });
      }
    },
    editRestrictDetails() {
      var restrict = this.getSelectedRestrict();
      if (restrict) {
        if (restrict.type == "set") {
          this.$router.push("/contract/" + this.contract._id + "/restrictset/" + restrict._id);
        } else {
          this.$router.push("/contract/" + this.contract._id + "/restrict/" + restrict._id);
        }
      } else {
        this.$message.warning($tt("Не обрано жодної умови!"));
      }
    },
    deleteRestrict(event, params) {
      //var restrict = _.isObject(id) ? this.getSelectedRestrict() : this.getRestrictById(id);
      if (params) {
        this.$confirm($tt("Ви дійсно бажаєте видалити умову [{name}]?", { name: params.data.name }), $tt("Увага"), {
          confirmButtonText: $tt("Так"),
          cancelButtonText: $tt("Ні"),
          type: "warning",
        })
          .then(() => {
            var contractId = this.$store.getters["contracts/contract"]()._id;
            this.$store
              .dispatch("restricts/del", { contractId: contractId, restrictId: params.data._id })
              .then(() => {
                this.$message.success($tt("Умову видалено!"));
              })
              .catch((err) => {
                globalErrorMessage($tt("Помилка видалення!"), err);
              });
          })
          .catch((err) => {});
      } else {
        var selectedRestricts = this.gridApi.getSelectedRows();
        if (selectedRestricts && selectedRestricts.length > 0) {
          this.$confirm($tt("Обрано {length} умов! Ви дійсно бажаєте видалити ці умови?", { length: selectedRestricts.length }), $tt("Увага"), {
            confirmButtonText: $tt("Так"),
            cancelButtonText: $tt("Ні"),
            type: "warning",
          })
            .then(() => {
              var contractId = this.$store.getters["contracts/contract"]()._id;
              this.$store.dispatch("main/setGlobalLoading", $tt("Видалення умов..."));
              selectedRestricts
                .reduce(
                  (prev, restrict) =>
                    prev
                      .then(() => this.$store.dispatch("restricts/del", { contractId: contractId, restrictId: restrict._id }))
                      .catch((err) => {
                        globalErrorMessage($tt("Помилка видалення!"), err);
                        this.$store.dispatch("main/setGlobalLoading");
                      }),
                  Promise.resolve()
                )
                .then(() => {
                  this.$message.success($tt("Умови видалено!"));
                  this.$store.dispatch("main/setGlobalLoading");
                })
                .catch(() => this.$store.dispatch("main/setGlobalLoading"));
            })
            .catch((err) => {});
        }
      }
    },
    copyRestrict() {
      var restrict = this.getSelectedRestrict();
      if (restrict) {
        bus.$emit("forms.restricts.edit.show", {
          title: $tt("Створення копії умови"),
          operation: "copy",
          initModel: _.extend(restrict, { name: "_" + restrict.name }),
        });
      }
    },
    restrictSelectDataCommit(data) {
      if (data && data.restricts && data.restricts.length) {
        var that = this;
        var restrictSrc = this.getSelectedRestrict();
        this.$store.dispatch("main/setGlobalLoading", $tt("Клонування структур..."));
        this.$store
          .dispatch("restricts/loadOne", { id: this.contract._id, id2: restrictSrc._id })
          .then(() => {
            var srcOrgs = _.clone(that.$store.getters["restricts/orgs"]());
            var srcSKU = _.clone(that.$store.getters["restricts/restrictSKU"]());
            data.restricts
              .reduce(
                (prev, restrict) =>
                  prev.then(() => {
                    return new Promise((resolve, reject) => {
                      setTimeout(() => {
                        switch (data.operation) {
                          case "orgs": {
                            that.$store.dispatch("main/setGlobalLoading", "Клонування структур..." + restrict.name);
                            that.$store
                              .dispatch("restricts/replaceOrgs", {
                                contractId: that.contract._id,
                                restrictId: restrict._id, // <-- for this restrict
                                newOrgs: srcOrgs, // <-- replace orgs to this orgs
                              })
                              .then(() => {
                                resolve();
                              })
                              .catch((err) => {
                                globalErrorMessage($tt("Помилка оновлення структур!"), err);
                                that.$store.dispatch("main/setGlobalLoading");
                              });
                            break;
                          }
                          case "sku": {
                            that.$store.dispatch("main/setGlobalLoading", "Клонування товарів..." + restrict.name);
                            that.$store
                              .dispatch("restricts/replaceSKU", {
                                contractId: that.contract._id,
                                restrictId: restrict._id, // <-- for this restrict
                                newSKU: srcSKU, // <-- replace orgs to this SKU
                              })
                              .then(() => {
                                resolve();
                              })
                              .catch((err) => {
                                globalErrorMessage($tt("Помилка оновлення товарів!"), err);
                                that.$store.dispatch("main/setGlobalLoading");
                              });
                            break;
                          }
                        }
                      }, 1);
                    });
                  }),
                Promise.resolve() // first resolve empty
              )
              .then(() => {
                that.$store.dispatch("restricts/load", { id: that.contract._id }).then(() => {
                  this.$message.success($tt("Умови оновлено!"));
                });
                that.$store.dispatch("main/setGlobalLoading");
              });
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка завантаження умови!"), err);
          });
      }
    },
    cloneStructures() {
      var restrictSrc = this.getSelectedRestrict();
      if (restrictSrc) {
        if (restrictSrc._orgsCount > 0) {
          bus.$emit("forms.restricts.select.show", {
            title: $tt("Вибір умов для клонування структур"),
            excludeForSelect: [restrictSrc],
            operation: "orgs",
          });
        } else {
          this.$message.warning($tt("Обрано умову без структур!"));
        }
      } else {
        this.$message.warning($tt("Не обрано жодної умови!"));
      }
    },
    cloneSKU() {
      var restrictSrc = this.getSelectedRestrict();
      if (restrictSrc) {
        if (restrictSrc._skuCount > 0) {
          bus.$emit("forms.restricts.select.show", {
            title: $tt("Вибір умов для клонування товарів"),
            excludeForSelect: [restrictSrc],
            operation: "sku",
          });
        } else {
          this.$message.warning($tt("Обрано умову без товарів!"));
        }
      } else {
        this.$message.warning($tt("Не обрано жодної умови!"));
      }
    },
    goToEditRestrictDetails(contractId, restrictId, type) {
      if (restrictId) {
        if (type == "set") {
          this.$router.push("/contract/" + this.contract._id + "/restrictset/" + restrictId);
        } else {
          this.$router.push("/contract/" + contractId + "/restrict/" + restrictId);
        }
      } else if (this.selectedRestrictId) {
        if (restrict.type == "set") {
          this.$router.push("/contract/" + this.contract._id + "/restrictset/" + +this.selectedRestrictId);
        } else {
          this.$router.push("/contract/" + contractId + "/restrict/" + this.selectedRestrictId);
        }
      } else {
        this.$message.warning($tt("Умову не обрано"));
      }
    },
    restrictCommitChanges(data) {
      if (data.operation == "create") {
        var contractId = this.$store.getters["contracts/contract"]()._id;
        var loading = this.$loading({ target: "#EditRestrictDialog>div", text: $tt("Будь ласка, зачекайте...") });
        this.$store
          .dispatch("restricts/create", { contractId, data: data.model })
          .finally(() => {
            loading && loading.close();
          })
          .then((newData) => {
            bus.$emit("forms.restricts.edit.hide");
            this.$message.success("Умову створено!");
            var node = this.gridApi.getRowNode(newData.data._id);
            if (node) node.setSelected(true);
            this.goToEditRestrictDetails(contractId, newData.data._id, newData.data.type);
          })
          .catch((err) => {
            globalErrorMessage("Помилка створення!", err);
          });
      } else if (data.operation == "update") {
        var loading = this.$loading({ target: "#EditRestrictDialog>div", text: $tt("Будь ласка, зачекайте...") });
        this.$store
          .dispatch("restricts/update", { contractId: this.$store.getters["contracts/contract"]()._id, restrictId: data.model._id, data: data.model })
          .finally(() => {
            loading && loading.close();
          })
          .then(() => {
            bus.$emit("forms.restricts.edit.hide");
            var rowNode = this.gridApi.getRowNode(data.model._id);
            rowNode.setData(data.model);
            this.$message.success("Умову оновлено!");
          })
          .catch((err) => {
            globalErrorMessage("Помилка збереження!", err);
          });
      } else if (data.operation == "copy") {
        var loading = this.$loading({ target: "body", text: $tt("Будь ласка, зачекайте...") });
        this.$store
          .dispatch(
            "restricts/copy",
            _.extend(data.model, { contractId: this.$store.getters["contracts/contract"]()._id, restrictId: this.selectedRestrictId, name: data.model.name })
          )
          .finally(() => {
            loading && loading.close();
          })
          .then((newData) => {
            bus.$emit("forms.restricts.edit.hide");
            this.$message.success("Копію договору створено!");
            var node = this.gridApi.getRowNode(newData.data._id);
            if (node) node.setSelected(true);
          })
          .catch((err) => {
            globalErrorMessage("Помилка копіювання!", err);
          });
      }
    },
    restrictImportData(data) {
      var that = this;
      bus.$emit("restricts.forms.select.file.clear");
      if (data.operation == "restrict.import") {
        // import contract
        that
          .readJSONFile(data.file)
          .then((result) => {
            if (result && result.dataType && result.dataType == "restrict" && result.data) {
              var contract = this.$store.getters["contracts/contract"]();
              result.data.name = result.data.name + " (imported " + new Date().toLocaleDateString() + " at " + new Date().toLocaleTimeString() + ")";
              result.data.beginDate = contract.beginDate;
              result.data.endDate = contract.endDate;
              this.$store.dispatch("main/setGlobalLoading", "Збереження умови...");
              that.$store
                .dispatch("restricts/create", { contractId: contract._id, data: result.data })
                .finally(() => {
                  that.$store.dispatch("main/setGlobalLoading");
                })
                .then((newData) => {
                  that.$message.success("Умову створено!");
                  var node = that.gridApi.getRowNode(newData.data._id);
                  if (node) node.setSelected(true);
                })
                .catch((err) => {
                  globalErrorMessage("Помилка створення!", err.data.msg);
                });
            } else {
              globalErrorMessage("Імпорт даних", "Помилка в структурі JSON файла!");
            }
          })
          .catch((err) => {
            globalErrorMessage("Помилка імпорту!", err);
          });
      }
    },
    restrictImportDataFromXLS(data) {
      var that = this;
      bus.$emit("forms.restrict.import.xls.clear");
      if (data.operation == "restrict.import.xls") {
        // import contract form XLS
        if (data && data && data.data && data.data.length) {
          var contract = this.$store.getters["contracts/contract"]();
          this.$store.dispatch("main/setGlobalLoading", "Збереження умови...");
          // import Restricts
          data.data
            .reduce((prev, restrict) => {
              return prev
                .then(() => this.saveImportedRestrict(contract, restrict))
                .catch((err) => {
                  this.$store.dispatch("main/setGlobalLoading");
                });
            }, Promise.resolve())
            .then(() => {
              // import SKU Dictionary
              this.$store.dispatch("main/setGlobalLoading", "Оновлення договору...");
              this.$store.dispatch("contracts/loadOne", { id: contract._id }).then(() => {
                var skuAll = data.data.reduce((prevSKU, restrict) => restrict.sku.concat(prevSKU), []);
                this.$store
                  .dispatch("contracts/updateSKUDictionary", { id: contract._id, sku: skuAll })
                  .then(() => this.$store.dispatch("main/setGlobalLoading"))
                  .catch((err) => {
                    globalErrorMessage("Помилка оновлення довідника товарів!", err);
                  });
              });
            })
            .catch((err) => {
              this.$store.dispatch("main/setGlobalLoading");
              globalErrorMessage("Помилка збереження умов!", err);
            });
        }
      }
    },
    saveImportedRestrict(contract, restrict) {
      return new Promise((resolve, reject) => {
        restrict = _.extend(restrict, {
          name: restrict.restrictName,
          beginDate: contract.beginDate,
          endDate: contract.endDate,
          type: restrict.restrictType ? restrict.restrictType : "purchase",
          planMeasure: restrict.restrictPlanMeasure ? restrict.restrictPlanMeasure : contract.planMeasure ? contract.planMeasure : "pcs",
          bonusMeasure: restrict.restrictBonusMeasure ? restrict.restrictBonusMeasure : "purchase",
          _orgsCount: 0,
          _skuCount: restrict.sku.filter((sku) => sku.drugsId).length,
        });
        // Create Restrict
        this.$store.dispatch("main/setGlobalLoading", "Збереження умови..." + restrict.restrictName);
        this.$store
          .dispatch("restricts/create", { contractId: contract._id, data: restrict })
          .finally(() => {})
          .then((newData) => {
            // Create Rule and append it to restrict
            var rule = {
              plan: restrict.restrictPlan,
              bonus: restrict.restrictBonus,
              applyCondition: "and",
              parentRules: [],
              notes: null,
            };
            this.$store
              .dispatch("restricts/insertRule", { contractId: contract._id, restrictId: newData.data._id, data: rule })
              .then(() => {
                // Insert SKU to restrict
                var sku = restrict.sku.filter((sku) => sku.drugsId);
                this.$store
                  .dispatch("restricts/insertSKU", {
                    contractId: contract._id,
                    restrictId: newData.data._id,
                    data: restrict.sku.filter((sku) => sku.drugsId),
                  })
                  .then(() => {
                    resolve();
                  })
                  .catch((err) => {
                    globalErrorMessage($tt("Помилка збереження товарів!"), err);
                  });
              })
              .catch((err) => {
                globalErrorMessage($tt("Помилка створення правила!"), err);
                reject(err.data.msg);
              });
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка створення!"), err);
            reject(err.data.msg);
          });
      });
    },
    exportRestrictToJSON(restrict) {
      if (restrict) {
        var loading = this.$loading({ target: "body", text: $tt("Будь ласка, зачекайте...") });
        this.$store
          .dispatch("restricts/loadOne", { id: this.contract._id, id2: restrict._id })
          .finally(() => {
            loading && loading.close();
          })
          .then((data) => {
            var dt = new Date()
              .toLocaleString()
              .split(",")
              .join("-");
            this.saveDataToJSON({ dataType: "restrict", data: data.data.data }, data.data.data.name + "_" + dt + ".json");
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка експорту!"), err);
          });
      }
    },
    importRestrictFromJSON() {
      bus.$emit("restricts.forms.select.file.show", {
        title: $tt("Імпорт умови із файлу JSON"),
        operation: "restrict.import",
        initModel: {},
      });
    },
    importRestrictFromXLSX() {
      var contract = this.$store.getters["contracts/contract"]();
      bus.$emit("restricts.forms.select.file.xls.show", {
        title: $tt("Завантаження умови із файлу XLS"),
        operation: "restrict.import.xls",
        initModel: {
          planMeasure: contract.planMeasure ? contract.planMeasure : "pcs",
          restrictExists: this.restricts,
        },
      });
    },
    handleClick(command) {
      switch (command) {
        case "export-to-json": {
          this.exportRestrictToJSON(this.getSelectedRestrict());
          break;
        }
        case "import-resrtict-from-json": {
          this.importRestrictFromJSON();
          break;
        }
        case "import-restrict-from-xlsx": {
          this.importRestrictFromXLSX();
          break;
        }

        default:
          break;
      }
    },
    onGridColumnsChange() {
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        clearTimeout(this.changeColumnsTimer);
        this.saveGridState(this.gridOptions, "restricts");
      }, 500);
    },
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
    getContextIcon(icon) {
      return `<img class="svg" src="${require(`../assets/icons/${icon}.svg`)}" />`;
    },
    contextMenuItems(params) {
      var contextMenu = [];
      var that = this;
      contextMenu.push(
        {
          name: $tt("Фільтр для кожного стовпчика"),
          icon: that.getContextIcon("apply_for_column_green"),
          action() {
            that.toggleFloatingFilter(that.gridOptions, that.columns);
          },
        },
        "separator"
      );
      contextMenu.push("export");
      return contextMenu;
    },
    onKeyDownHandler(e) {
      if (e.ctrlKey) {
        if (e.key.toLowerCase() == "f" || e.key.toLowerCase() == "а") {
          this.toggleFloatingFilter(this.gridOptions, this.columns);
          e.preventDefault();
          return false;
        }
      }
    },
  },
};
export default Restricts;
</script>

<style lang="scss" scope>
.el-main {
  padding: 0;
  background: #fff;
  padding: 0px !important;
  height: calc(100vh - 114px);
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 136px;
}

.invalid {
  background: rgb(253, 171, 171);
  transition: all 1s;
}
</style>
